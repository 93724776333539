@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
 @import './base.css'; 
@import 'tailwindcss/utilities'; */

 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x:  clip;
}

ul {
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120vh;
}

.secondary-section {
  background: #b5b5b5;
}

section h2 {
  font-size: 5rem;
  margin-bottom: 2rem;
} 

.header {
  @apply text-white p-4 fixed bg-none w-full z-50;
  
}

.headerActive {
  @apply text-white p-4 fixed bg-gray-800 w-full bg-opacity-70 z-50;
  
}

.sectionHeader {

  @apply  text-2xl md:text-6xl font-extrabold 
}


.profilePicSize {

  @apply  w-[90px] h-[90px] md:w-[160px] md:h-[160px]
}

.profilePicHoverTranslate {
  @apply translate-x-[45px] md:translate-x-[80px]
}

.profilePicTranslate {
  @apply -translate-x-[43px] md:-translate-x-[80px]

}

.profileText {

@apply text-sm md:text-lg
}

#backgroundwithgradient {


  background-image:linear-gradient(rgba(37, 78, 13, 0.9), rgba(48, 228, 78, 0.9)), url('../public/353.png')

}

 /*
    background: none;
  color: #fff;
  padding: 1rem;
  position: fixed;
  width: 100%;
  */